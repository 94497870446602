export const tooltip_bom_file = `Your BOM must be an excel file(.xlsx or .xls) in the zip file you upload.`

export const zipFileTypes = [
  "application/x-zip-compressed",
  "application/zip",
]

export const fileTypesForBOM = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
  "application/vnd.ms-excel"
]

export const acceptedBOMFileExtensions = [
  "xlsx", "xls", "csv", "txt"
]

export const invalid_temp_dirs = [
  "__MACOSX",
]

export const isTemporalDirectory = (filename) => {
  const temp_dir = filename.split('/')[0]
  return invalid_temp_dirs.includes(temp_dir)
}

export const isValidBomFile = (filename) => {
  const file_extension = filename.split('.').pop()
  const temp_dir = filename.split('/')[0]
  return acceptedBOMFileExtensions.includes(file_extension) && !invalid_temp_dirs.includes(temp_dir)
}