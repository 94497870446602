import React from 'react';
import { Outlet } from "react-router-dom";
import LayoutType from 'src/shared/utils/layout_type';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ConfigProvider } from 'antd';

const MainLayout = ({layoutType = LayoutType.FRONT_SITE}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#01abc9",
          fontFamily: "Manrope, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
        },
      }}
    >
      <div className="ligth text-foreground bg-background main-layout">
        <Header layoutType={layoutType} />
        <Outlet />
        <Footer layoutType={layoutType} />
        <ToastContainer position="top-center" hideProgressBar autoClose={3000} draggable />
      </div>
    </ConfigProvider>
  );
};

export default MainLayout;
