import React from 'react';

const PrivacyPolicyPage = () => {

  return (
    <div className="container" id="privacy-policy">
      <span className='date-subtitle mb-1'>June, 2024</span><br />
      <h3 className='title mb-3'>BOM Admin Privacy Policy</h3>
      <div className='content'>
        <p>
          This site provides multiple benefits to our customers, which require the sharing 
          of private information from our customers. BOM Admin takes every precaution to 
          maintain the security measures for our customers.
        </p>
        <p>
          <span className='subtitle'>How do we protect your privacy?</span><br />
          We use security measures to protect against the loss, misuse and alteration of 
          data used by our system.
        </p>
        <p>
          All of our information is stored on a secured server that is backed by VeriSign. Click 
          on the small lock in the corner of your browser to verify our certification (note: 
          the lock only appears on our secured pages during the order process).
        </p>
        <p>
          Your information is stored for your convenience. We periodically send e-mail to you 
          with helpful information about BOM Admin, updates to the site, and information 
          about new products.
        </p>
        <p>
          How can you stop us from sending you more email?<br/>
          We periodically send e-mail to you with helpful information about BOM Admin, 
          updates to the site, and information about new products.
        </p>
        <p>
          Every e-mail we send to you contains an easy, automated way for you to opt-out or 
          stop receiving email from us. If you wish to do this, simply follow the instructions 
          at the end of any email sent through this system.
        </p>
        <p>
          The transmission of unsolicited commercial email is expressly forbidden from our 
          site. If you have received unwanted, unsolicited email sent from BOM Admin, via 
          this system or purporting to be sent via this system, please use our contact us 
          page and inform us immediately so we can further investigate.
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicyPage;