import React, { useState } from 'react';
import LayoutType from 'src/shared/utils/layout_type';
import UserIcon from 'images/user-icon.png';

import { Link as LinkRR } from 'react-router-dom';

import {
  NavbarItem,
  Link,
  DropdownItem,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  Avatar,
  Button,
  User,
} from "@nextui-org/react";

const Anonymous = ({reloadDocument}) => {
  return (
    <NavbarItem className="">
      <LinkRR to="/login" reloadDocument={reloadDocument} className="text-white">Login</LinkRR>
    </NavbarItem>
  )
}

const MenuAccount = ({layoutType, token, isLoadingUser, currentUser, userError, handleLogout}) => {

  const reloadFrontLink = layoutType !== LayoutType.FRONT_SITE;
  const reloadAccountLink = layoutType !== LayoutType.ACCOUNT_SITE;

  if (!token || isLoadingUser || !currentUser || userError) {
    return (
      <Anonymous reloadDocument={reloadFrontLink} />
    )
  }

  const avatarUrl = currentUser.avatar_url || UserIcon;

  // Note: remove padding from dropdown item so the inside link use all space available to click
  return (
    <>
      <Dropdown placement="bottom-end">
        <DropdownTrigger>
          <User
            name={currentUser.name}
            as="button"
            className="text-white"
            avatarProps={{
              src: avatarUrl,
              size: "sm",
            }}
          />
        </DropdownTrigger>
        <DropdownMenu aria-label="Profile Actions" variant="flat" itemClasses={{
          base: [
            'px-0',
            'py-0',
          ],
        }}>
          <DropdownItem key="profile" isReadOnly className="h-14 gap-2 px-2 py-1.5 cursor-default">
            <p className="font-semibold">Signed in as</p>
            <p className="font-semibold">{currentUser.email}</p>
          </DropdownItem>
          <DropdownItem key="team_settings">
            <LinkRR to="/account" reloadDocument={reloadAccountLink} className="px-2 py-1.5 block">Profile</LinkRR>
          </DropdownItem>
          <DropdownItem key="analytics">
            <LinkRR reloadDocument={reloadAccountLink} to="/account/my-boms" className="px-2 py-1.5 block">My BOMs</LinkRR>
          </DropdownItem>
          <DropdownItem key="settings">
            <LinkRR reloadDocument={reloadAccountLink} to="/account/preferences" className="px-2 py-1.5 block">My Settings</LinkRR>
          </DropdownItem>
          <DropdownItem key="logout" color="danger" className="px-2 py-1.5" onPress={handleLogout}>
            Log Out
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  )
};

export default MenuAccount;
