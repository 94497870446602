import React from "react";
import { Link as LinkRR, useLocation } from 'react-router-dom';
import { Button, Link } from '@nextui-org/react';

import LogoWhite          from "images/logo-white.png";
import LayoutType         from 'src/shared/utils/layout_type';
import FacebookIconWhite  from "images/facebook-icon-white.svg";
import YoutubeIconWhite   from "images/youtube-icon-white.svg";

const MenuBomTool = ({ reloadDocument }) => (
  <Link reloadDocument={reloadDocument} to="/">
    <span style={{ marginLeft: '4px' }}>BOM Tool</span>
  </Link>
)

const Footer = ({layoutType}) => {

  const reloadFrontLink = layoutType !== LayoutType.FRONT_SITE;

  return (
    <footer className="app-footer">
      <div>
        <div className="text-center">
          <div className="flex flex-col md:flex-row place-content-center items-center flex-column flex-md-row align-items-center justify-content-center">
            <img src={LogoWhite} className="logo" alt="Logo White"/>
            <span>Your opinion is important to us</span>
            {/*<a href="mailto:support@boomadmin.com" className="btn btn-outline-light btn-feedback">Feedback</a>*/}
            <Button as={Link} href="mailto:mailto:support@boomadmin.com" color="white" variant="ghost" className="text-white md:ml-2">
              Feedback
            </Button>
          </div>
          <div className="info-links-wrap">
            <ul className="flex place-content-center gap-2 mb-3 info-links ">
              <li className="list-inline-item info-link">
                <LinkRR to="/contact-us" reloadDocument={reloadFrontLink}>
                  <span>Contact Us</span>
                </LinkRR>
              </li>
              <li className="list-inline-item info-link">
                <LinkRR reloadDocument={reloadFrontLink} to="/terms-and-conditions">
                  <span>Terms and Conditions</span>
                </LinkRR>
                <a href="#"></a>
              </li>
              <li className="list-inline-item info-link">
                <LinkRR reloadDocument={reloadFrontLink} to="/privacy-policy">
                  <span>Privacy Policy</span>
                </LinkRR>
              </li>
            </ul>
          </div>
          <div className="copyrights">
            Boom Admin. All content © Copyrights {new Date().getFullYear()}. All reserved.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;