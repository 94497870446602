import React, {useState, useEffect}   from "react";
import { Button, useDisclosure } from '@nextui-org/react';

import HomeBanner                     from "images/home-banner.png"

import LoginModal                     from "src/shared/components/LoginModal";


import { activateInputFile }          from "src/shared/utils/file_input";
import { isValidBomFile }             from "./helpers/BomFileTypes";
import { isUserLoggedIn }             from 'src/shared/utils/session'
import { useCreateNewBomMutation }    from "src/services/rtk_api/bom/BomApi";

const MainContent = (props) => {

  const [createNewBom, { isSuccess, isError, data: new_bom }] = useCreateNewBomMutation({fixedCacheKey: 'shared-create-bom'});
  const [showLoginModal, setShowLoginModal] = useState(false);
  const {isOpen: isModalOpen, onOpen: onModalOpen, onOpenChange: onModalOpenChange} = useDisclosure();

  const file_input_id = "upload-bom-input";

  const onChangeBomFile = (e) => {
    const selected_file = e.target.files[0]
    if(!selected_file) {
      alert("No file uploaded\nPlease try again")
      return;
    }

    if(!isValidBomFile(selected_file.name)) {
      alert("Invalid uploaded file\nPlease upload a valid BOM file\nSupported formats: .xls, .xlsx, .csv, .txt")
      return;
    }

    const form_data = new FormData()
    form_data.append('bom[file]', selected_file)
    form_data.append('bom[origin]', 'upload')
    createNewBom({formData: form_data})
  }

  const onClickUploadBom = () => {
    if(!isUserLoggedIn()){
      onModalOpen()
      return;
    }

    activateInputFile(`#${file_input_id}`)
  }

  const onLogin = () => {
    setShowLoginModal(false)
    activateInputFile(`#${file_input_id}`)
  }

  const onCancelLogin = () => {
    setShowLoginModal(false)
  }

  useEffect(() => {
    if(isSuccess) window.location.href = `/boms/${new_bom.id}/parse`
  }, [isSuccess])

  useEffect(() => {
    if(isError) alert("There was an error uploading the new BOM file\nPlease try again")
  }, [isError])

  return (
    <section className="main-content">
      <div className="container">
        <div className="bom-quote">
          <div className="grid grid-cols-12 align-items-center">
            <div className="col-span-12 md:col-span-5">
              <h1 className="title text-white">
                BOM QUOTE
              </h1>
              <h4 className="text-white">
                An Intelligent Electronic <br/>
                Component Search Engine
              </h4>
              <p className="my-3">
                The X-Parts Wizard allows you to “automatically” control
                the amount of extra parts based on footprint.<br/>
                Upload your BOM in one of the following formats: .xls, xlsx, .csv
              </p>
              <div className="my-3 text-center lg:text-left">
                <div className="input-file-container">
                  <Button className="bg-orange-500 text-white" onClick={onClickUploadBom}>
                    <i className="fas fa-arrow-circle-up"></i> UPLOAD YOUR BOM
                  </Button>
                  <input type="file" className="form-control"
                    id={file_input_id} placeholder="Bom File"
                    name="bom[file]"
                    onChange={(e) => onChangeBomFile(e)} hidden/>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-7 text-center text-lg-end bom-preview">
              <img src={HomeBanner} alt="BOM preview" className="img-fluid"/>
            </div>
          </div>
        </div>
      </div>
      <LoginModal
        isOpen={isModalOpen}
        onOpenChange={onModalOpenChange}
        onOk={onLogin}
        onCancel={onCancelLogin}/>
    </section>
  )
}

export default MainContent;