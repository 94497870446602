import React from 'react';
import { Link as LinkRR } from 'react-router-dom';
import {
  NavbarContent,
  NavbarItem,
  Link,
} from "@nextui-org/react";
import LayoutType from 'src/shared/utils/layout_type';

import { useGetCurrentUserQuery } from 'src/services/rtk_api/user/UserApi';
import { useLogoutMutation } from 'src/services/rtk_api/user/sessionApi';


const Menu = ({layoutType}) => {
  const {data: currentUser, error, isLoading} = useGetCurrentUserQuery();

  const reloadFrontLink = layoutType != LayoutType.FRONT_SITE;

  return (
    <NavbarContent className="hidden sm:flex gap-4" style={{flexGrow: 0}}>
      <NavbarItem>
        <Link color="foreground" as={LinkRR} to="/" className="text-white" reloadDocument={reloadFrontLink}>
          BOM Tool
        </Link>
      </NavbarItem>
      <NavbarItem>
        <Link color="foreground" as={LinkRR} to="/" className="text-white menu-link" reloadDocument={reloadFrontLink}>
          About Us
        </Link>
      </NavbarItem>
      <NavbarItem>
        <Link color="foreground" as={LinkRR} to="/contact-us" href="/contact-us" className="text-white menu-link" reloadDocument={reloadFrontLink}>
          Contact Us
        </Link>
      </NavbarItem>
    </NavbarContent>
  );
};

export default Menu;