import React            from "react";

import MainContent      from "./components/main_content/MainContent";
import Advantages       from "./components/advantages/Advantages";

const HomePage = () => {

  return (
    <div id="welcome">
      <MainContent />
      <Advantages />
    </div>
  )
}

export default HomePage;