import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Link as LinkRR } from 'react-router-dom';
import LayoutType from 'src/shared/utils/layout_type';
import Menu from './Menu';
import MenuMobile from './MenuMobile';
import MenuAccount from './MenuAccount';
import Logo from 'images/logo.svg';

import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarMenuToggle,
} from "@nextui-org/react";

import { useGetCurrentUserQuery } from 'src/services/rtk_api/user/UserApi';
import { useLogoutMutation } from 'src/services/rtk_api/user/sessionApi';
import classNames from "classnames";

const Header = ({layoutType}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [token, setToken] = useState(undefined);

  const {data: currentUser, error, isLoading} = useGetCurrentUserQuery();
  const [logout, { isLoading: logoutLoading, error: logoutError, isSuccess: logoutSuccess }] = useLogoutMutation();

  const reloadFrontLink = layoutType !== LayoutType.FRONT_SITE;

  const handleLogout = (e) => {
    logout();
  }

  useEffect(() => {
    if (logoutSuccess) {
      Cookies.remove('X-User-Token');
      window.location.href = '/'
    }
  }, [logoutSuccess])

  useEffect(() => {
    setToken(Cookies.get('X-User-Token'))
  }, [])

  const klassContainer = classNames({
    'container': layoutType !== LayoutType.BOM_QUOTE,
  })

  return (
    <div className="main-layout-header">
      <div className={klassContainer}>
        <Navbar maxWidth="full" onMenuOpenChange={setIsMenuOpen} className="bg-transparent">
          <NavbarContent>
            <NavbarMenuToggle
              aria-label={isMenuOpen ? "Close menu" : "Open menu"}
              className="sm:hidden"
            />
            <NavbarBrand>
              <LinkRR to="/" reloadDocument={reloadFrontLink}>
                <img src={Logo} className="h-14 abc" alt="Logo" />
              </LinkRR>
            </NavbarBrand>
          </NavbarContent>


          <NavbarContent justify="end" className="grow-0">
            <Menu layoutType={layoutType} />
            <MenuAccount
              layoutType={layoutType}
              token={token}
              isLoadingUser={isLoading}
              currentUser={currentUser}
              userError={error}
              handleLogout={handleLogout} />
          </NavbarContent>
          <MenuMobile layoutType={layoutType} />
        </Navbar>
      </div>
    </div>
  )
}

export default Header;