import React from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom'


import MainLayout from 'src/layouts/MainLayout';

import HomePage from 'src/front_site/home/HomePage'
import SessionPage from 'src/front_site/sessions/SessionPage'
import PrivacyPolicyPage from 'src/front_site/privacy_policy/PrivacyPolicyPage';
import TermsConditionsPage from 'src/front_site/tems_conditions/TermsConditionsPage';
import ContactUsPage from 'src/front_site/contact_us/ContactUsPage';


export default (
  <Routes>
    <Route path="/" element={<MainLayout />}>
      <Route index element={<HomePage />} />
      <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="terms-and-conditions" element={<TermsConditionsPage />} />
      <Route path="contact-us" element={<ContactUsPage />} />
      <Route path="login" element={<SessionPage />} />
    </Route>
  </Routes>
)