import React from "react";

import Advantage1 from "images/advantage-1.png"
import Advantage2 from "images/advantage-2.png"
import Advantage3 from "images/advantage-3.png"
import Advantage4 from "images/advantage-4.png"

const Advantages = (props) => {

  return (
    <section className="advantages">
      <div className="container">
        <h2 className="section-title text-center">
          WHY USE BOM ADMIN?
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 align-items-top">
          <div className="col-md-6 col-lg-3">
            <div className="advantage">
              <div className="icon">
                <img src={Advantage1} alt="Advantage 1" className="mx-auto	"/>
              </div>
              <div className="title">
                Save time and money
              </div>
              <div className="">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, 
                sed diam nonummy nibh euismod tincidunt ut laoreet dolore 
                magna aliquam 
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="advantage">
              <div className="icon text-center">
                <img src={Advantage2} alt="Advantage 2" className="mx-auto"/>
              </div>
              <div className="title">
                Detailed Report Of Found Parts
              </div>
              <div className="text">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, 
                sed diam nonummy nibh euismod tincidunt ut laoreet dolore 
                magna aliquam 
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="advantage">
              <div className="icon text-center">
                <img src={Advantage3} alt="Advantage 3" className="mx-auto"/>
              </div>
              <div className="title">
                Best selection of  vendors and manufacturers
              </div>
              <div className="text">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, 
                sed diam nonummy nibh euismod tincidunt ut laoreet dolore 
                magna aliquam 
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="advantage">
              <div className="icon text-center">
                <img src={Advantage4} alt="Advantage 4" className="mx-auto"/>
              </div>
              <div className="title">
                Choose the package of your convenience
              </div>
              <div className="text">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, 
                sed diam nonummy nibh euismod tincidunt ut laoreet dolore 
                magna aliquam 
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Advantages;